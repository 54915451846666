body[dir='rtl'] {
  *:not(.material-icons):not(vg-player),
  .gpt-turbo .form-ai .put-questions .hint {
    font-family: 'Tajawal', sans-serif;
  }

  .pagination,
  .list-inline,
  .list-unstyled {
    padding-right: 0;
    padding-left: 1em !important;
  }

  vg-player,
  [dir='ltr'],
  .iti__country,
  owl-carousel-o,
  .wpwl-container,
  .iti__flag-container,
  ngx-material-timepicker-container,
  .mat-tab-label .mat-tab-label-content,
  .certificates .scroll-bar .certificate,
  .course-booking .mat-horizontal-content-container,
  metutors-complete-tutor-profile-tutoring-courses .form-label bdi {
    direction: ltr;
  }

  .mat-tab-label .mat-tab-label-content {
    unicode-bidi: isolate;
  }

  .slide .content .request-free span {
    text-decoration: none !important;
  }

  .benefits-tutoring p bdi,
  .featured-tutors .card-item,
  .success-stories .card-item,
  .cdk-global-overlay-wrapper,
  .loving-opportunities .card-item,
  .homework-help .content .field-card,
  .course-booking .mat-horizontal-content-container .mat-horizontal-stepper-content {
    direction: rtl;
  }

  .ms-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
  }

  .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }

  .ms-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }

  .ms-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }

  .ms-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }

  .ms-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .me-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important;
  }

  .me-2,
  .list-inline-item:not(:last-child) {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }

  .me-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }

  .me-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }

  .me-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }

  .me-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  .ps-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }

  .ps-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important;
  }

  .ps-4 {
    padding-left: 0!important;
    padding-right: 1.5rem!important;
  }

  .pe-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }

  .pe-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }

  .text-start,
  .mat-option,
  .mat-menu-item,
  .student-profile .basic-table tr td.text-end {
    text-align: right !important;
  }

  .text-end,
  .loving-opportunities .buttons-list {
    text-align: left !important;
  }

  .empty-state-option {
    text-align: center !important;
  }

  .float-start,
  .success-stories rating span.bs-rating-star.active {
    float: right !important;
  }

  .Online,
  .Offline,
  .float-end,
  .homework-help .content .buttons-list button {
    float: left !important;
  }

  .loving-opportunities button {
    float: unset !important;
  }

  .terms-sidebar ul li a.active:before {
    border-color: transparent transparent transparent var(--main-color) !important;
    margin-left: 10px !important;
    margin-right: -4px !important;
  }

  .gpt-full .swipe,
  .why-we-teach img,
  .how-works .container,
  .innovative-approach img,
  .chatbot-icon button img,
  .how-works .container >div,
  metutors-study-slide .slide,
  metutors-blogs-slide .slide,
  .learn-smarter .card-try svg,
  .complete-profile .truck img,
  metutors-about-slide .slide img,
  metutors-blogs-slide .slide img,
  .slide .content .play-video svg,
  .chatbot .ask-anything .send-btn,
  .homework-help .content .svg-icon,
  metutors-become-tutor-slide .slide,
  .learn-smarter .card-swipes button,
  metutors-home-slider .slide .overlay,
  metutors-study-slide .slide .container,
  metutors-blogs-slide .slide .container,
  .gpt-turbo .form-ai .put-questions img,
  metutors-prices .explore-heading .contact-img,
  metutors-become-tutor-slide .slide .container,
  .slide .content .request-free svg-icon .svg-icon,
  .featured-tutors .card-item .card-details h2 a svg,
  metutors-explore-tutors .explore-heading .contact-img,
  metutors-academic-learning-environment .academic-learning,
  metutors-academic-learning-environment .academic-learning .container {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  }

  .messageInfoWrapperStyle {
    align-self: flex-start;
    flex-direction: row-reverse;
  }

  .recieptContainer {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse;
  }

  .quick-search .home-search .search-area button {
    font-size: 13px !important;
  }

  .tutor-card .actions .book.has-message {
    font-size: 11px !important;
    padding: 0 3px !important;
  }

  .gpt-full .l-0 {
    padding-left: 0.75rem !important;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.08) inset !important;
  }

  /********** MARGIN LEFT **************/

  .invoice-header span,
  .list-items .menu img,
  .tutor-card .list .icon,
  .tutor-navbar .svg-icon,
  .student-navbar .svg-icon,
  .classroom-item .secondary-btn,
  .tutors .tutor-item .list .icon,
  .tutor-card .specialization > span,
  .featured-tutors .card-item .list .icon,
  .tutors .tutor-item .specialization span,
  .classes-dashboard .card .list .menu img,
  .review-request .row-block .list-tutors .count,
  .featured-tutors .card-item .specialization > span,
  .review-request .row-block .list-tutors .li-item img,
  .select-tutor .custom-list .list-tutors .li-item img {
    margin-right: 0 !important;
    margin-left: 5px;
  }

  .home-header.home-slider .nav-item {
    margin-right: 0 !important;
    margin-left: 20px !important;
  }

  .home-header .nav-item:last-child,
  .footer .subscribe .social a:last-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .about-me ul li img,
  .home-header .btn-nav,
  .classroom .class-btn,
  .video-notes li:before,
  .home-header .nav-item,
  .qualification ul li img,
  .qualification ul li img,
  .why-teaching-us ul li img,
  .classroom-item .details-btn,
  .select-tutor button.back-btn,
  .program-list .programs .item,
  .list-classroom button.back-btn,
  .review-request button.back-btn,
  .classroom-info button.back-btn,
  .learning-environment .tabs button,
  .benefits-online-tutoring ul li img,
  .account-settings .avatar-block img,
  .homework-help .content .field-card,
  .signup-block form .types button svg,
  .availability-tutoring .top-info span,
  .feedback-rating .feedback-block .review h2,
  .tutor-availability .border-section .tabs button,
  .payment-records .circle-tab-group .mat-tab-label,
  .introjs-tooltip .introjs-tooltipbuttons .introjs-prevbutton {
    margin-right: 0 !important;
    margin-left: 10px !important;
  }

  .my-table.default-title tr th label,
  .opinion rating span.bs-rating-star.active,
  .success-stories rating span.bs-rating-star.active {
    margin-right: 0 !important;
    margin-left: 4px;
  }

  .list-days button,
  .video .edit-video,
  .featured-tutors .card-item,
  .request-course .cancel-btn,
  .footer .subscribe .social a,
  .course-details-popup .list-videos .video h3,
  .classroom-details-popup .list-videos .video h3 {
    margin-right: 0 !important;
    margin-left: 15px;
  }

  .tutor-navbar .icons .message,
  .tutor-navbar .icons .timezone,
  .student-navbar .icons .message,
  .student-navbar .icons .timezone,
  .tutor-navbar .icons .notification,
    .student-navbar .icons .notification {
    margin-right: 0 !important;
    margin-left: 30px;
  }

  .classroom .codes span {
    margin-right: 0 !important;
    margin-left: 35px;
  }

  .share .icon img {
    margin-right: 0 !important;
    margin-left: 8px;
  }

  .classroom-item button.status {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .loving-opportunities .card-item {
    margin-left: 30px;
    margin-right: 10px;
  }

  .tutors-list .mat-tab-label,
  .profile-header .details ul li,
  .interview-list .mat-tab-label,
  .payment-records .mat-tab-label {
    margin-left: 50px !important;
    margin-right: 0 !important;
  }

  /************* MARGIN RIGHT ************************/

  .signup-block form p a,
  .class-details .card ul li span {
    margin-left: 0 !important;
    margin-right: 15px;
  }

  .feedback-rating,
  .tutor-card .count,
  .tutor-card .review,
  .sidebar ul li span,
  .lang-info mat-icon,
  .tutors .tutor-item .count,
  .tutors .tutor-item .review,
  .select-tutor .est-price h1,
  .why-metutors .card .item h2,
  .dashboard .card-item .count,
  .review-request .est-price h1,
  .classroom-info .est-price h1,
  .list-classroom .est-price h1,
  .dashboard .card-item .review,
  .feedback-rating h1.title span,
  .featured-tutors .card-item .count,
  .certificates .card .rating .count,
  .featured-tutors .card-item .review,
  .classroom-item .in-progress strong,
  .send-message form .file-upload > div,
  .course-info .topics .add-new-topic .add-btn,
  .classes-dashboard .card .in-progress strong,
  .list-classroom .list-classes table tr .actions button,
  .course-card .course-body .no-tutors .mat-radio-label-content,
  .course-card .course-body .list-classes table tr .actions button {
    margin-left: 0 !important;
    margin-right: 10px;
  }

  .lang-info span,
  .list-items .menu span,
  .tutor-navbar span.title,
  .tutor-card .actions .more,
  .course-card .list .menu span,
  .profile-header .actions .more,
  .certificates .card .info h2 img,
  .tutors .tutor-item .actions .more,
  .certificates .card .rating .review,
  .certificates .card .info h2 .count,
  .profile-header .details ul li .count,
  .profile-header .details ul li .review,
  .classes-dashboard .card .list .menu span,
  .featured-tutors .card-item .actions .more,
  .select-tutor .custom-list .list-tutors .count,
  .review-request .row-block .list-tutors .count,
  .select-tutor .custom-list .list-tutors .review,
  .review-request .row-block .list-tutors .review,
  .feedback-rating .rating-block rating span.bs-rating-star {
    margin-left: 0 !important;
    margin-right: 5px;
  }

  .privacy-notice .margin-50,
  .terms-condition .margin-50 {
    margin-right: 50px;
    margin-left: 0 !important;
  }

  .privacy-notice .card p .margin-20,
  .terms-condition .card p .margin-20 {
    margin-right: 20px;
    margin-left: 0 !important;
  }

  .dashboard .items .item {
    margin-right: 3px;
    margin-left: 0 !important;
  }

  .user-prefrences .btn-list,
  .account-settings .btn-list {
    margin-right: -25px;
    margin-left: calc(-0.5 * 1.5rem) !important;
  }

  /*************** PADDING MARGIN 4 **********************/

  .slide .item img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .rotate-180-ar {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .footer .subscribe .form-block input {
    padding: 10px 10px 10px 80px !important;
  }

  .ticket-details .card .write-comment input {
    padding: 0 60px 0 130px !important;
  }

  .about-students .form-block input {
    padding: 20px 45px 20px 80px !important;
  }

  .sidebar-btn {
    margin: 25px 5px 0 0 !important;
  }

  .explore-heading .form-block input {
    padding: 20px 20px 20px 80px !important;
  }

  .gpt-turbo .form-ai input {
    padding: 10px 20px 10px 30px !important;
  }

  .all-courses .fields-list .mat-chip.mat-standard-chip,
  .explore-tutors .fields-list .mat-chip.mat-standard-chip {
    padding: 10px 20px 10px 15px !important;
  }

  .video-notes li img {
    margin: 3px 0 0 6px !important;
  }

  .homework-help .content .buttons-list button:first-child {
    order: 2;
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }

  /****************** POSITIONS ********************/

  .alert-item .icon,
  .sidebar.has-sidemenu .inbox-link .soon,
  .submit-interview .form-group .mat-datepicker-toggle.datepicker-toggle,
  .course-booking .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before,
  .course-booking .mat-step-label.mat-step-label-active:not(.mat-step-label-selected):after {
    left: 5px !important;
    right: auto !important;
  }

  .l-0,
  .sidebar-btn,
  .gpt-full .swipe,
  .alert-item:before,
  .sn-progress-loader,
  .how-works .container .title h3,
  .profile-header .avatar-image:after,
  .dropdown-menu.show.country-dropdown,
  .ticket-details .card .write-comment img,
  .iti--allow-dropdown .iti__flag-container,
  .iti--separate-dial-code .iti__flag-container,
  .course-booking .mat-step-label.mat-step-label-active.mat-step-label-selected:before,
  .course-booking .mat-step-label.mat-step-label-active:not(.mat-step-label-selected):before,
  .course-booking .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child):before {
    left: auto !important;
    right: 0 !important;
  }

  .edit-cover,
  .edit-video,
  .delete-cover,
  .post-card .status,
  .tutors .tutor-item h2 span,
  .gpt-turbo .form-ai .file-upload,
  .about-students .form-block button,
  .explore-heading .form-block button,
  .featured-tutors .card-item h2 span,
  form.gpt-card.card-shadowed .file-upload,
  .slide .content .actions .coming-soon img,
  .quick-search .home-search .search-area .advance-block .reset-btn {
    left: 10px;
    right: auto !important;
  }

  .tutor-card span.badge,
  .profile-header .status-interview,
  .services-features .card .card-body img,
  .payment-information .list-cards .visa-card .checked,
  .quick-search .home-search .search-area mat-icon.search {
    right: 10px;
    left: auto !important;
  }

  .footer .subscribe .form-block button {
    left: 2px;
    right: auto !important;
  }

  .how-work ul li span sup {
    right: -6px;
    left: auto !important;
  }

  .course-card .badge-item {
    right: 7px;
    left: auto !important;
  }

  .gpt-turbo .form-ai .put-questions img {
    right: -65px;
    left: auto !important;
  }

  .about-students .form-block mat-icon,
  .quick-search .home-search .search-area .search {
    right: 15px;
    left: auto !important;
  }

  .r-0,
  .close-btn,
  .country button,
  .carousel-indicators,
  .subscribe-modal .close-btn,
  .success-stories .stories .quotes,
  .chatbot-icon .chatbot-desc .close-chat,
  .ticket-details .card .write-comment .reply-button,
  .course-booking .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child):after {
    left: 0 !important;
    right: auto !important;
  }

  .learning-made-easy .item:not(:last-child):after {
    right: calc(50% - 30px);
    left: auto !important;

    @extend .rotate-180-ar;
  }

  .change-schedule .close-btn,
  .slide .content .actions .coming-soon {
    left: -10px;
    right: auto !important;
  }

  .gpt-turbo .steps .gradient {
    right: -10px;
    left: auto !important;
  }

  .list-classroom .list-classes table tr .actions {
    left: -100%;
    right: auto !important;
  }

  form.signin-form .eye,
  .signup-form .password-visibility,
  .reset-block form .password-visibility,
  .security-settings .password-visibility,
  .list-classroom .list-classes table tr:hover .actions {
    left: 20px;
    right: auto !important;
  }

  .profile-header .avatar-name .img-status mat-icon,
  .account-settings .avatar-name .img-status mat-icon {
    left: calc(50% - 15px);
    right: auto !important;
  }

  .delete-topic {
    right: 35px;
    left: auto !important;
  }

  .program-list button {
    left: 24px;
    right: auto !important;
  }

  .profile-header .cover .upload-cover,
  .list-options-rows .mat-radio-container {
    left: 15px;
    right: auto !important;
  }

  .share .share-img {
    left: 8px;
    right: auto !important;
  }

  .availability {
    left: 60px;
    right: auto !important;
  }

  .homework-help .content .svg-icon {
    left: 150px;
    right: auto !important;
  }

  .list-options-rows .list-tutors .mat-radio-container,
  .sidemenu-btn {
    right: 5px !important;
    left: auto !important;
  }

  .learning-environment .chevron-right,
  .select-program-category .chevron-right {
    left: -17.5px;
    right: auto !important;
  }

  .learning-environment .chevron-left,
  .select-program-category .chevron-left {
    right: -17.5px;
    left: auto !important;
  }

  .slide .content .request-free svg-icon {
    left: -40px;
    right: auto !important;
  }

  .sidemenu {
    right: 65px;
    left: auto !important;
  }

  .quick-search .home-search .search-area mat-icon.close {
    left: 100px;
    right: auto !important;
  }

  .course-card .course-body .class-item .apply-btn {
    right: auto !important;
    left: 4px;
  }

  .gpt-full .r-0.swipe-right {
    right: auto !important;
    left: calc(-20% + 5vh) !important;
  }

  .profile-header .verified {
    left: 25px;
    right: auto !important;
  }

  /**************** PADDING ***********************/

  .iti.separate-dial-code input,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 input,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 input,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
    padding-right: 98px;
    padding-left: 6px;
  }

  metutors-otp-verify .align {
    padding-right: 39px;
    padding-left: 0 !important;
  }

  .card-item .card-item,
  .itemDetailStyle {
    padding-right: 15px;
    padding-left: 0 !important;
  }

  .my-table.default-title tr th span {
    padding-left: 0.5rem !important;
    padding-right: 0;
  }

  .share input {
    text-align: left;
    padding-left: 30px;
    padding-right: 0.75rem !important;
  }

  .sidemenu ul,
  .sidebar ul {
    padding: 0 !important;
  }

  .list-items .menu {
    padding-left: 0 !important;
    padding-right: calc(1.5rem * 0.5) !important;
  }

  .footer .copyright ul li:after,
  .copyright-content ul li:after {
    padding-right: 5px;
    padding-left: 0 !important;
  }

  .quick-search .home-search .search-area input {
    padding-left: 100px !important;
    padding-right: 235px !important;
  }

  .p-s-260 {
    padding-right: 260px;
    padding-left: 0 !important;
  }

  .success-stories .action {
    padding-right: 1rem!important;
    padding-left: 1.5rem!important;
  }

  .gpt-full .r-0 {
    padding-left: 0 !important;
    padding-right: 50px !important;
  }

  /************** BORDERS ***********************/

  metutors-about-slide .slide .info .border-right {
    border-left: 1px solid rgba(226, 226, 226, 0.4);
    border-right: none !important;
  }

  .exceptional-opportunities .border-right {
    border-left: 1px solid #e2e2e2;
    border-right: none !important;
  }

  .payment-information .list-cards .visa-card {
    border-left: 1px solid #e0e0e0;
    border-right: none !important;
  }

  .sidebar.has-sidemenu {
    border-right: none !important;
    border-left: 1px solid rgba(226, 226, 226, 0.2);
  }

  .sidebar ul li a.active {
    border-left: none !important;
    border-right: 2px solid var(--main-color);
  }

  .settings .card ul li.active {
    border-left: none !important;
    border-right: 5px solid var(--main-color);
  }

  .success-stories .stories .story .main-image {
    border-right: 8px solid var(--main-color);
    border-left: 1px solid var(--main-color) !important;
  }

  .signup-block .steps ul li {
    border-right: 5px solid #e2e2e2;
    border-left: none !important;
  }

  .course-card .topics-table th:first-child {
    border-left: none !important;
    border-top-right-radius: 10px;
    border-right: 1px solid #e5e1e6;
    border-top-left-radius: 0 !important;
  }

  .course-card .topics-table th:last-child {
    border-right: 0 !important;
    border-top-left-radius: 10px;
    border-left: 1px solid #e5e1e6;
    border-top-right-radius: 0 !important;
  }

  .gpt-full .gpt-sidebar .item-store ul {
    border-left: none !important;
    border-right: 1px solid #e0e0e0;
  }

  .gpt-full .gpt-sidebar .item-store ul li:not(.active):hover {
    border-left: none !important;
    border-right: 1px solid var(--main-color);
  }

  .count-list .items .card-details:not(:last-child) {
    border-right: none !important;
    border-left: 1px solid #ececee;
  }

  /********************** BORDER RADIUS *****************************/

  .alert-item:before {
    border-radius: 3px 0 0 3px !important;
  }

  .ticket-details .card .list-messages p {
    border-radius: 8px 0 8px 8px !important;
  }

  .have-coupon button,
  .gpt-full .gpt-sidebar .item-store,
  .footer .subscribe .form-block button {
    border-radius: 4px 0 0 4px !important;
  }

  .have-coupon input {
    border-radius: 0 4px 4px 0 !important;
  }

  .page-item:last-child .page-link {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .page-item:first-child .page-link {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  .gpt-full .gpt-sidebar .item-store ul li.active,
  .gpt-full .gpt-sidebar .item-store ul li:not(.active):hover {
    border-radius: 8px 0 0 8px !important;
  }

  /**************** MEDIA QUERY ***********************/

  @media (max-width: 1199px) {
    .gpt-full .l-0 {
      left: auto !important;
      right: -100% !important;

      &.open-gpts {
        right: 0 !important;
        left: auto !important;
        border-radius: 16px 0 0 16px !important;
        box-shadow: 0px 2px 5px 0px #3BB3C180 !important;
      }
    }

    .gpt-full .r-0 {
      left: -100% !important;
      right: auto !important;

      &.open-history {
        left: 0 !important;
        right: auto !important;
        border-radius: 0 16px 16px 0 !important;
        box-shadow: 0px 2px 5px 0px #3BB3C180 !important;
      }
    }
  }

  @media (max-width: 767px) {
    .benefits-tutoring img {
      margin-left: 10px;
      margin-right: 0 !important;
    }

    .course-details-popup .list-videos .video h3 {
      margin-left: 5px;
      margin-right: 0 !important;
    }

    .text-start-sm {
      text-align: right !important;
    }

    .quick-search .home-search .search-area {
      input {
        padding-left: 70px !important;
        padding-right: 160px !important;
      }

      mat-icon.close {
        left: -50px;
        right: auto !important;
      }
    }
  }

  @media (max-width: 992px) {
    .sidebar {
      right: -340px;
      left: auto !important;

      &.open-sidebar {
        right: 0;
        left: auto !important;
        border-radius: 16px 0 0 16px !important;
      }
    }

    .sidemenu {
      right: -260px;
      left: auto !important;
    }
  }

  @media (min-width: 768px) {
    .offset-md-1 {
      margin-right: 8.33333333%;
      margin-left: 0;
    }

    .offset-md-2 {
      margin-right: 16.66666667%;
      margin-left: 0;
    }

    .offset-md-3 {
      margin-right: 25%;
      margin-left: 0;
    }

    .offset-md-4 {
      margin-right: 33.33333333%;
      margin-left: 0;
    }

    .offset-md-8 {
      margin-right: 66.66666667%;
      margin-left: 0;
    }

    .area {
      margin-right: 20%;
      margin-left: 0 !important;
    }
  }

  @media (min-width: 992px) {
    .offset-lg-4 {
      margin-right: 33.33333333%;
      margin-left: 0;
    }

    .offset-lg-8 {
      margin-left: 0;
      margin-right: 66.66666667%;
    }

    .offset-lg-2 {
      margin-right: 16.66666667%;
      margin-left: 0;
    }

    .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0;
    }
  }

  @media (max-width: 576px) {
    .learning-environment .chevron-right,
    .select-program-category .chevron-right {
      left: -10px;
      right: auto !important;
    }

    .learning-environment .chevron-left,
    .select-program-category .chevron-left {
      right: -10px;
      left: auto !important;
    }

    .calendar-fill {
      margin-left: .5rem;
      margin-right: 0 !important;
    }

    .ps-2-xs {
      padding-left: 0 !important;
      padding-right: .5rem !important;
    }

    .me-3-xs {
      margin-right: 0 !important;
      margin-left: 1rem !important;
    }
  }
}

@media (max-width: 400px) {
  .quick-search .home-search .search-area.search-area-modal {
    button.advance-btn {
      font-size: 12px !important;
    }

    .search {
      right: 5px !important;
      left: auto !important;
      font-size: 15px !important;
    }
  }
}