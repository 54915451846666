/* You can add global styles to this file, and also import other style files */
@import 'prismjs/themes/prism-okaidia.css';
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
@import 'prismjs/plugins/line-highlight/prism-line-highlight.css';

* {
  outline: none;
}

html,
body {
  height: 100%;
}

:root {
  --main-color: #3bb3c1;
  --main-orange: #ff8e18;
}

body {
  margin: 0;
  font-family: 'Proxima Nova', sans-serif;
  // font-family: 'Inter', sans-serif;
  color: #5d6678;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1d273b;
}

*,
.mat-fab,
mat-label,
.mat-button,
.mat-select,
.mat-mini-fab,
.mat-menu-item,
.mat-paginator,
.mat-list-option,
.mat-icon-button,
.mat-flat-button,
.mat-dialog-title,
.mat-raised-button,
.mat-stroked-button,
.mat-form-field-label,
.mat-expansion-panel-header,
.mat-paginator-page-size .mat-select-trigger {
  font-family: 'Proxima Nova', sans-serif;
  // font-family: 'Inter', sans-serif;
}

.mat-form-field.mat-form-field-disabled {
  cursor: not-allowed;
}

button:focus,
button:hover {
  outline: none;
}

.min-h-100 {
  min-height: 100%;
}

.full-height {
  height: 100vh;
}

.form-group {
  margin-bottom: 1rem;
}

.wpwl-form {
  margin-bottom: 5px;
}

.mat-menu-item,
.mat-option {
  text-align: left !important;
}

.cdk-global-overlay-wrapper {
  direction: ltr;
}

.tooltip-main-color,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
.mat-calendar-body-selected,
.mat-flat-button.mat-primary,
.mat-pseudo-checkbox-checked,
.mat-accent .mat-slider-thumb,
.mat-raised-button.mat-primary,
.mat-accent .mat-slider-track-fill,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-slider-thumb-label,
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-checked,
.mat-tab-nav-bar.mat-primary .mat-ink-bar,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb,
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-accent .mat-pseudo-checkbox-indeterminate .mat-primary .mat-pseudo-checkbox-indeterminate,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple) {
  background-color: var(--main-color);
}

.mat-slider-thumb,
.timepicker__header,
.mat-slider-track-fill,
.mat-slider-thumb-label,
.clock-face__clock-hand,
.clock-face__number > span.active {
  background-color: var(--main-color) !important;
}

.timepicker-backdrop-overlay {
  z-index: 9999 !important;
}

.mat-calendar-body-cell-content {
  border-radius: 8px !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-radius: 8px !important;
  background-color: rgb(59 179 193 / 30%);
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #12121233 !important;
}

.mat-calendar-body-disabled {
  cursor: not-allowed !important;
}

.mat-spinner circle,
.mat-progress-spinner circle {
  stroke: var(--main-color);
}

.main-color,
.timepicker-button {
  color: var(--main-color) !important;
}

.orange-color {
  color: var(--main-orange) !important;
}

.timepicker-overlay {
  z-index: 9999 !important;
}

.total-bookings .heading .progress-spinner svg,
.view-assignments .heading .progress-spinner svg {
  width: 40px !important;
  height: 40px !important;
}

.view-feedback .rating-block rating span.bs-rating-star {
  margin-right: 10px;
  font-size: 20px;

  &.active {
    color: var(--main-color);
  }
}

.mat-chip.mat-standard-chip {
  font-size: 13px;
  color: #2a2a2a;
  background: #fff;
  border: 1px solid #e2e2e2;
}

a {
  color: var(--main-color);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--main-color);
  }
}

.iti {
  width: 100%;

  .iti__country,
  input#country-search-box {
    padding: 10px 15px;
  }

  .iti__selected-flag {
    background: #e2e2e2;
    transition: all 0.25s ease-in-out;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #e6e7ea;
}

::-webkit-scrollbar-thumb {
  background: #7b8391;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #1d273b;
}

.card {
  // box-shadow: 0 4px 44px rgba(17, 29, 48, 0.06);
  box-shadow: 0 4px 44px rgba(17, 29, 48, 0.26);
  // box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.3);
  transition: ease-in-out 0.3s;
  border: none;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
  font-size: 14px;
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
  font-size: 14px;
}

::placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
  font-size: 14px;
}

.overflow-height {
  height: auto;
  overflow: auto;
}

.mat-slider-horizontal .mat-slider-track-background {
  transform: none !important;
}

.main-button {
  color: #5d6678;
  background-color: transparent;
  border: 1px solid #dcdfe5 !important;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: var(--main-color);
    color: #fff;
  }
}

.main-button-transparent {
  border-radius: 5px !important;
  border: solid 1px var(--main-color) !important;
  background-color: rgba(255, 255, 255, 0);
  font-weight: bold;
  color: var(--main-color) !important;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: var(--main-color) !important;
    color: #fff !important;

    svg,
    svg path {
      fill: #fff !important;
    }
  }
}

.secondary-button-transparent {
  border: 1px solid #b2b2b2 !important;
  color: #3d3b3b !important;
  font-weight: bold;
  border-radius: 5px !important;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: #b2b2b2 !important;
    color: #fff !important;

    svg,
    svg path {
      fill: #fff !important;
    }
  }
}

.danger-button-transparent {
  border-radius: 5px !important;
  border: solid 1px var(--bs-danger) !important;
  background-color: rgba(255, 255, 255, 0);
  font-weight: bold;
  color: var(--bs-danger) !important;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: var(--bs-danger) !important;
    color: #fff !important;

    svg,
    svg path {
      fill: #fff !important;
    }
  }
}

.mat-button-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.main-button-opacity {
  background-color: rgb(59 179 193 / 15%) !important;
  color: var(--main-color) !important;
  font-weight: bold;
  padding: 0 15px !important;

  &.request-makeup {
    color: var(--main-orange) !important;
    background-color: rgb(255 142 24 / 10%) !important;
  }
}

.secondary-button-opacity {
  background-color: #e2e2e2 !important;
  color: #707070 !important;
  font-weight: bold;
  padding: 0 15px !important;
}

rating span.bs-rating-star {
  width: 16px;
  height: 18px;
  font-size: 18px;
  display: inline-block;
  font-weight: bold;

  &.active {
    color: var(--main-orange);
  }
}

.introjs-overlay {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.10);
}

.introjs-tooltip {
  padding: 30px;
  min-width: 400px;
  max-width: 500px;
  border-radius: 16px;
  box-shadow: rgba(59, 179, 193, 0.5) 0px 2px 5px 0px;

  &.custom-top-tooltip {
    top: 200px;
  }

  &.custom-center-tooltip {
    border-radius: 16px;
    background: #ECFDFF;
    box-shadow: 0px 2px 5px 0px rgba(59, 179, 193, 0.50);

    .introjs-arrow {
      border-bottom-color: #ECFDFF;
    }
  }

  .introjs-arrow {
    border-width: 10px;

    &.left {
      top: 40px;
      left: -20px;
    }

    &.top {
      top: -20px;
    }

    &.bottom {
      bottom: -20px;
    }

    &.right {
      top: 40px;
      right: -20px;
    }
  }

  .introjs-tooltiptext {
    color: #000;
    font-size: 15px;

    .f-size-16 {
      font-size: 16px;
    }
  }

  .introjs-tooltipbuttons {
    margin-top: 15px;
    overflow: hidden;

    .introjs-skipbutton {
      float: left;
      margin-right: 0;
      font-size: 15px;
      margin-top: 5px;
      color: var(--main-color);

      &.introjs-donebutton {
        color: #fff;
        font-size: 15px;
        padding: 5px 30px;
        border-radius: 4px;
        display: inline-block;
        background-color: var(--main-color);
      }
    }

    .introjs-prevbutton {
      font-size: 15px;
      margin-right: 10px;

      &.introjs-disabled {
        display: none;
      }
    }

    .introjs-nextbutton {
      color: #fff;
      font-size: 15px;
      padding: 5px 20px;
      border-radius: 4px;
      display: inline-block;
      background-color: var(--main-color);

      &.introjs-disabled {
        display: none;
      }
    }
  }
}

.custom-intro-highlight {
  border: none;
  box-shadow: none;
  background-color: #fff;
}

metutors-tutor-badges,
metutors-tutor-settings,
metutors-tutor-dashboard,
metutors-tutor-classrooms,
metutors-student-settings,
metutors-student-dashboard,
metutors-student-classrooms,
metutors-tutor-notifications,
metutors-student-certificates,
metutors-student-notifications,
metutors-tutor-payment-records,
metutors-student-payment-records,
metutors-student-view-certificate {
  display: flex;
  flex-direction: column;
  height: 100%;
}

metutors-tutor,
metutors-student,
mat-dialog-content,
metutors-admin-dashboard {
  .mat-tab-header-pagination {
    display: none !important;
  }

  .mat-tab-label-container {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.break-all {
  word-break: break-all;
}

.min-w-0 {
  min-width: 0 !important;
}

.flash-status {
  animation: flash 1s linear 0s infinite;
}

.p-s-260 {
  padding-left: 260px;
}

.custom-input {
  min-height: 46px;
  padding: 10px 19px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #1d273b;
  background-color: #ffffff;
  background-image: none;
  border-radius: 4px;
  -webkit-appearance: none;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 44px rgba(17, 29, 48, 0.06);
  border-color: transparent;

  &:focus {
    box-shadow: none;
  }

  &.input-bordered {
    border: 1px solid #dcdfe5;

    &:focus {
      border: 1px solid #9d9fa3;
    }

    &:hover {
      border-color: var(--main-color);
    }
  }

  &.ng-invalid.ng-touched {
    border: 1px solid #d41b12;
  }

  &.has-error {
    border: 1px solid #d41b12;
  }
}

.auth-input {
  border-radius:  16px;
  padding: 8px 16px;
  font-size: 16px;
  height: 50px;
  border: 0.8px solid #E7E7E7;
  background:  rgba(239, 241, 249, 0.60);

  &.ng-invalid.ng-touched {
    border: 0.8px solid #FFC7C7;
    background: rgba(252, 243, 242, 0.70);
  }

  &.has-error {
    border: 0.8px solid #FFC7C7;
    background: rgba(252, 243, 242, 0.70);
  }
}

.text-error {
  color: #F57E77;
  font-size: 12px;
}

.rounded-btn {
  font-size: 16px !important;
  font-weight: 400 !important;
  border-radius: 16px !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-global-overlay-wrapper {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.10);
}

.cdk-global-overlay-wrapper:has(.filter-blur) {
  backdrop-filter: blur(30px);
}

.cdk-global-overlay-wrapper:has(.rounded-green),
.cdk-global-overlay-wrapper:has(.filter-blur-10) {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.10);
}

.rounded-green .mat-dialog-container {
  border-radius: 16px;
  box-shadow: 0px 2px 5px 0px rgba(59, 179, 193, 0.50);
}

.list-items {
  padding: 5px 0;

  .menu {
    padding-right: 0;

    img {
      margin-right: 5px;
    }

    span {
      color: #2a2a2a;
      margin-left: 5px;
      font-size: 20px;
    }

    strong {
      font-size: 17px;
      color: #696969;
      font-weight: 500;
    }
  }

  &.sm-item {
    .menu {
      span {
        font-size: 14px;
      }

      strong {
        font-size: 13px;
      }
    }
  }

  &.md-item {
    .menu {
      span {
        font-size: 16px;
      }

      strong {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

.page-link {
  color: #5d6678;

  &:focus,
  &:hover {
    box-shadow: none;
    color: #5d6678;
  }
}

.page-item.active .page-link {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 15px 0 10px;
  border-top: 5px solid transparent;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: transparent;
  border: 1px solid var(--main-color);
  border-radius: 4px;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: transparent;
}

.grey-field.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #807e7e;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--main-color);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--main-color) !important;
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  outline: none;
}

.mat-menu-panel {
  min-height: 50px !important;

  &.currencies-menu {
    max-width: 800px;
    margin-top: 1rem;
  }

  &.notification-menu {
    max-width: 1000px;
    min-width: 25rem;
    max-height: 25rem;
    pointer-events: none !important;
  }
}

.mat-menu-content {
  min-width: 230px;

  .mat-menu-item {
    &:not(.h-auto) {
      padding: 6px 16px;
      height: auto;
    }

    .mat-icon.icon {
      background-color: #f9fbff;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      color: #7e838b;
      margin-left: 5px !important;
    }

    .mat-icon.chevron {
      margin-left: 0 !important;
    }
  }
}

.rotate-90 {
  transition: all 0.25s ease-in-out;
  transform: rotate(90deg);
}

.rotate-180 {
  transition: all 0.25s ease-in-out;
  transform: rotate(180deg);
}

.list-options-rows {
  .mat-radio-label-content {
    width: 100%;
  }

  .options mat-radio-button:hover {
    border: 0.5px solid var(--main-color) !important;

    .mat-radio-container .mat-radio-outer-circle {
      border-color: var(--main-color);
    }
  }

  .mat-radio-group.options .mat-radio-label {
    padding: 20px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .list-tutors .mat-radio-label {
    padding: 15px 10px;
    border-bottom: 1px solid #e2e2e2;
  }
}

.spinner {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.bread-crumb {
  background-color: #f9fbff;
  padding: 15px 0;

  h2 {
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #2c343b;
  }

  ul li {
    a {
      color: #5d6678;
    }

    &.active {
      font-weight: 400;
      color: #1d273b;
    }

    &:not(:last-child):after {
      content: '/';
      color: #5d6678;
      margin-right: 10px;
    }

    &:not(:last-child) {
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }
}

.logos {
  img {
    object-fit: cover;
    width: 2rem;
    height: 2rem;
    margin-right: -0.3rem;
    margin-left: -0.3rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.my-table {
  border-radius: 8px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 8%);
  background-color: #ffffff;
  overflow: hidden;

  tr {
    border: none !important;

    th {
      padding: 10px;
      background-color: var(--main-color);
      color: #fff;
    }

    td {
      background-color: #ffffff;
      border-top: 1px solid #ededed;
      padding: 10px;
    }
  }

  &.orange-title tr th {
    background-color: #ff8300;
  }

  &.purple-title tr th {
    background-color: #be63f9;
  }

  &.default-title tr th {
    background-color: #fafafa;
    color: #2a2a2a;

    &.sm {
      font-size: 13px;
    }
  }
}

.me-table {
  background-color: #fff;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 8%);

  &.no-shadow {
    box-shadow: none;
  }

  tr {
    border: none !important;

    th {
      font-size: 13px;
      color: #0A090B;
      font-weight: 700;
      padding: 15px 10px;
      background: #E0F4F6;
      border-top: 1px solid #F1F1F1;
      border-bottom: 1px solid #F1F1F1;
    }

    td {
      color: #555;
      padding: 10px;
      font-size: 13px;
      border-top: 1px solid #ededed;

      &.hint-gray {
        background: #FAFAFA;
        font-size: 14px;
        color: #131B33;
      }

      bdi {
        padding: 0;
      }

      .text-absent {
        color: #DE1111;
        padding: 8px 15px;
        border-radius: 8px;
        background-color: #FFEAEA;
      }

      .text-join {
        border-radius: 8px;
        color: var(--main-color);
        padding: 0 20px !important;
        background-color: #F0FDFF;
      }

      .text-download {
        color: #fff;
        line-height: 0;
        border-radius: 8px;
        padding: 7px 15px !important;
        background-color: var(--main-color);
      }

      .text-view {
        color: #188A42;
        border-radius: 8px;
        padding: 0 20px !important;
        background-color: #F0FDF4;
      }

      .text-reschedule {
        border-radius: 8px;
        padding: 0 20px !important;
        color: #22C55E !important;
        border: 1px solid #22C55E;
        background-color: #F0FDF4 !important;
      }

      button.mat-button-disabled {
        opacity: 1;
        color: #6B6868 !important;
        border-color: #FAFAFA !important;
        background-color: #FAFAFA !important;
      }

      .action-btn {
        color: #949494;
        background: #fafafa;
        line-height: 0;
        min-width: 0;
        width: 56px;
        height: 40px;

        mat-icon {
          width: 20px;
          height: 20px;
          font-size: 20px;
        }
      }
    }
  }

  &.grey-title tr th {
    background: #FAFAFA;
  }

  &.green-title tr th {
    background: #F0FDF4;    
  }

  &.yellow-title tr th {
    background: #FBF3EB;
  }
}

.me-card {
  background: #fff;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &.sm-shadow {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }

  &.xl-shadow {
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);
  }

  .overview {
    color: #131b33;
    font-size: 20px;
    font-weight: 700;

    .view-all {
      font-size: 15px;

      &:hover {
        color: var(--main-color);
      }
    }

    .add-topic {
      font-size: 14px;
      border-radius: 8px;
      background: #F0FDFF;

      mat-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
        font-weight: bold;
      }

      &.mat-button-disabled {
        color: #6B6868 !important;
        border-color: #FAFAFA !important;
        background-color: #FAFAFA !important;
      }
    }
  }

  .heading {
    &:not(.border-none) {
      border-bottom: 1px solid var(--main-color);
    }

    h5 {
      color: #131b33;
      font-size: 18px;
      font-weight: 700;

      > span {
        font-size: 16px;
        font-weight: 400;
      }

      .status {
        height: 26px;
        font-size: 14px;
        font-weight: 700;
        padding: 8px 16px;

        &.active,
        &.success,
        &.accepted,
        &.approved,
        &.refunded,
        &.completed,
        &.inprogress,
        &.cancelled_classes {
          color: #22c55e;
          border: 1px solid #22c55e;
          background: rgba(34, 197, 94, 0.05);
        }

        &.pending,
        &.requested,
        &.payment_pending,
        &.payment_requested,
        &.requested_to_metutors,
        &.cancellation_inprogress {
          color: #c98843;
          border: 1px solid #c98843;
          background-color: rgba(201, 136, 67, 0.05);
        }

        &.upcoming,
        &.submitted,
        &.scheduled {
          border: 1px solid #5955cd;
          color: #5955cd;
          background-color: rgb(89, 85, 205, 0.05);
        }

        &.missed,
        &.expired,
        &.disputed,
        &.canceled,
        &.rejected,
        &.cancelled,
        &.suspended,
        &.payment_rejected,
        &.declined_by_teacher,
        &.cancelled_by_student,
        &.cancelled_by_teacher,
        &.cancelled_classes_by_student {
          color: #f00;
          border: 1px solid #f00;
          background: rgba(255, 0, 0, 0.05);
        }
      }
    }

    .class-btn {
      border-radius: 4px;
      border: 1px solid var(--main-color);
      background: #e2f4f6;
      color: var(--main-color);
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      padding: 8px 16px;

      &.booking-btn {
        border-radius: 20px;
        border: 1px solid #949494;
        color: #949494;
        line-height: 15px;
        background-color: transparent;
      }
    }
  }

  .content {
    .description {
      color: #111111;
      font-size: 15px;
    }

    .date {
      border-radius: 16px;
      background: #f0fdff;

      h2 {
        color: #807e7e;
        font-size: 14px;
      }

      h3 {
        color: #111;
        font-size: 20px;
      }

      h4 {
        color: #807e7e;
        font-size: 14px;

        span {
          color: #111;
        }
      }

      h6 {
        color: #28939f;
        margin-top: 10px;
        font-size: 14px;

        &.rejected {
          color: #d34c65;
        }
      }
    }

    .list-items {
      strong {
        color: #807e7e;
        font-size: 15px;
      }

      span {
        color: #111;
        font-size: 15px;
      }
    }

    .information {
      border-top: 1px solid #e6e6e6;

      .logos {
        .enrolled {
          color: #807e7e;
          font-size: 14px;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }

      .progress-card {
        h3 {
          color: #807e7e;
          font-size: 14px;
        }

        .progressbar {
          width: 250px;

          strong {
            color: #131b33;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      &.info-lg .progress-card .progressbar {
        width: 400px;
      }
    }
  }
}

.me-input {
  padding: 10px 15px;
  border: 1px solid #807e7e;

  &:focus {
    box-shadow: none;
  }

  &.ng-invalid.ng-touched {
    border: 1px solid #dc3545;
  }
}

.count-list {
  .items {
    border-radius: 16px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);

    .card-details {
      flex-grow: 1;
      position: relative;

      &:not(:last-child) {
        border-right: 1px solid #ececee;
      }

      h3 {
        color: #92959e;
        font-size: 15px;
        font-weight: 700;
      }

      span {
        color: #15192c;
        font-size: 14px;
      }

      &.active-card {
        h3 {
          color: var(--main-color);
        }

        span {
          color: #000;
        }

        &:after {
          top: 0;
          content: "";
          width: 15px;
          right: 10px;
          height: 15px;
          position: absolute;
          border-radius: 50%;
          display: inline-block;
          background: var(--main-color);
        }
      }
    }
  }
}

.search-block {
  button {
    position: absolute;
    top: 5px;
    left: 5px;
    color: #5d6678 !important;
    background-color: transparent !important;
    border: 1px solid #dcdfe5 !important;
    transition: all 0.25s ease-in-out;
  }

  input:focus ~ button {
    background-color: var(--main-color) !important;
    color: #fff !important;
    border-color: var(--main-color) !important;
  }
}

.cal-month-view .cal-days {
  border-color: #e6ebf0;
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #e6ebf0;
}

.cal-month-view .cal-cell-row {
  flex-direction: row-reverse;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #e6ebf0;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(255, 49, 21, 0.1);
}

.cal-month-view .cal-day-badge {
  background-color: var(--main-color);
  margin-right: 10px;
  margin-left: 0;
}

.cal-month-view .cal-day-number,
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #000;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #f7f7f7;
}

// mat slider
.mat-slider-wrapper,
.mat-slider-track-fill,
.mat-slider-track-wrapper,
.mat-slider-track-background {
  height: 11px !important;
  border-radius: 10px;
}

.mat-slider-thumb {
  bottom: -14px !important;
  height: 28px !important;
  width: 28px !important;
}

metutors-navbar-mobile {
  .p-sidebar {
    width: 90%;

    .p-sidebar-content {
      height: 100%;
    }

    .p-sidebar-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}

.mat-dialog-container {
  overflow: hidden !important;
}

.feedback .rating-block rating span.bs-rating-star {
  font-size: 13px;

  &.active {
    color: var(--main-color);
  }
}

span.status,
button.status {
  min-width: 0;
  color: #fff;
  padding: 15px;
  line-height: 0;
  border-radius: 25px;

  &.table-status {
    line-height: 36px;
    border-radius: 8px;
    padding: 0 20px !important;
  }

  &.active,
  &.offered,
  &.success,
  &.accepted,
  &.refunded,
  &.completed,
  &.inprogress,
  &.cancelled_classes {
    color: #027A48;
    background: #ECFDF3;
  }

  &.pending,
  &.requested,
  &.payment_pending,
  &.payment_requested,
  &.requested_to_metutors,
  &.cancellation_inprogress {
    color: #DF7800;
    background: rgb(223 120 0 / 10%);
  }

  &.upcoming,
  &.submitted,
  &.non-offered {
    color: #5955cd;
    background-color: #e1e0fe;
  }

  &.missed,
  &.expired,
  &.disputed,
  &.canceled,
  &.rejected,
  &.cancelled,
  &.payment_rejected,
  &.declined_by_teacher,
  &.cancelled_by_student,
  &.cancelled_by_teacher,
  &.cancelled_classes_by_student {
    color: #F00;
    background: rgb(255 98 0 / 10%);
  }

  &.suspended {
    background-color: #f15a5a;
    color: #fff;
  }
}

.statues li {
  padding: 5px 7px;

  &.active,
  &.success,
  &.accepted,
  &.refunded,
  &.completed,
  &.inprogress,
  &.cancelled_classes {
    color: #50c394;
    background: rgba(80, 195, 148, 0.2);
  }

  &.pending,
  &.requested,
  &.payment_pending,
  &.requested_to_metutors {
    background-color: #f8e3cc;
    color: #c98843;
  }

  &.enrolled {
    color: #ff8300;
    background-color: #ff83001a;
  }

  &.upcoming,
  &.submitted {
    color: #007cff;
    background-color: #e1e0fe;
  }

  &.missed,
  &.canceled,
  &.rejected,
  &.cancelled,
  &.declined_by_teacher,
  &.cancelled_by_student,
  &.cancelled_by_student,
  &.cancelled_by_teacher,
  &.cancelled_classes_by_student {
    color: #d34c65;
    background-color: #f9d3da;
  }

  &.suspended {
    background-color: #f15a5a;
    color: #fff;
  }
}

.send-feedback .rating-block rating span.bs-rating-star {
  font-size: 22px;
  margin-right: 12px;

  &.active {
    color: var(--main-color);
  }
}

.text-main,
rating span.bs-rating-star.active {
  color: var(--main-color);
}

.border-main {
  border: 1px solid var(--main-color) !important;
}

.w-24 {
  width: 24px;
}

.w-32 {
  width: 32px;
}

.blog-card-menu {
  border-radius: 14px;

  .mat-menu-content {
    min-width: 150px;

    .menu-blog-item {
      line-height: 35px;
    }
  }
}

.subject-option {
  height: auto !important;
  line-height: 20px !important;
  padding: 8px 25px !important;
  border-bottom: 1px solid #dad5d5;

  h4 {
    color: #2a2a2a;
    font-size: 17px;
    margin-bottom: 3px;
  }

  h6 {
    color: #707070;
    font-size: 13px;
    margin-bottom: 0;
  }

  h5 {
    color: #2a2a2a;
    font-size: 15px;
    margin-bottom: 3px;

    span {
      color: #707070;
      font-size: 13px;
    }
  }

  small {
    font-size: 13px;
    color: #707070;
  }

  &:last-child {
    border-bottom: none !important;
  }
}

.empty-state-option {
  height: auto !important;
  cursor: default !important;
  text-align: center !important;
  white-space: normal !important;
  pointer-events: none !important;

  &:hover:not(.mat-option-disabled),
  &:focus:not(.mat-option-disabled) {
    background: transparent !important;
  }

  h2 {
    color: #2a2a2a;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    color: #949494;
    font-size: 15px;
  }

  .request-course {
    font-size: 17px;
    pointer-events: all;
  }
}

.home-search-autocomplete.mat-autocomplete-panel {
  max-height: 60vh;
  margin-top: 5px;

  &.home-search-cont {
    max-height: 50vh;
  }

  &.modal-search {
    max-height: 40vh;
  }
}

.quick-countries-list {
  .mat-checkbox-layout {
    width: 100%;

    .mat-checkbox-label {
      color: #78767a;
      font-size: 12px;
      flex-grow: 1;
    }

    .mat-checkbox-frame {
      border-color: var(--main-color);
      border-width: 1px;
    }
  }
}

////////////////////////
// Placeholder
////////////////////////

$ph-bg: #fff !default;
$ph-color: #ced4da !default;
$ph-border-radius: 2px !default;

$ph-gutter: 20px !default;
$ph-spacer: 15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration: 0.8s !default;

.ph-item {
  width: 100%;

  &,
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: $ph-bg;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation $ph-animation-duration linear infinite;
    background: linear-gradient(
        to right,
        rgba($ph-bg, 0) 46%,
        rgba($ph-bg, 0.35) 50%,
        rgba($ph-bg, 0) 54%
      )
      50% 50%;
  }

  > * {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: ($ph-gutter / 2);
    padding-left: ($ph-gutter / 2);
  }
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  // margin-bottom: ($ph-spacer / 2);

  div {
    height: 10px;
    margin-bottom: ($ph-spacer / 2);
    background-color: $ph-color;
  }

  .big,
  &.big div {
    height: 20px;
    margin-bottom: $ph-spacer;
  }

  .empty {
    background-color: rgba($ph-bg, 0);
  }
}

.ph-col-2 {
  flex: 0 0 percentage(2 / 12);
}

.ph-col-3 {
  flex: 0 0 percentage(3 / 12);
}

.ph-col-4 {
  flex: 0 0 percentage(4 / 12);
}

.ph-col-6 {
  flex: 0 0 percentage(6 / 12);
}

.ph-col-8 {
  flex: 0 0 percentage(8 / 12);
}

.ph-col-10 {
  flex: 0 0 percentage(10 / 12);
}

.ph-col-12 {
  flex: 0 0 percentage(12 / 12);
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  height: 150px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
  border-radius: $ph-avatar-border-radius;
  overflow: hidden;

  &::before {
    content: ' ';
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/////////////////////

@media (max-width: 1200px) {
  :host ::ng-deep #ccc-icon {
    width: 50px;
    height: 50px;
  }

  .mt-15-lg {
    margin-top: 15px !important;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  .flex-column-lg {
    flex-direction: column !important;
  }

  .mb-15-lg {
    margin-bottom: 15px !important;
  }

  .p-0-lg {
    padding: 0 !important;
  }

  .w-50-lg {
    width: 50% !important;
  }

  .justify-content-start-lg {
    justify-content: start !important;
  }

  .w-100-lg {
    width: 100% !important;
  }

  .mt-10-lg {
    margin-top: 10px !important;
  }

  .border-none-lg {
    border: none !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .mb-20-md {
    margin-bottom: 15px !important;
  }

  .mt-15-md {
    margin-top: 15px !important;
  }

  .w-100-md {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .text-center-md-sm {
    text-align: center !important;
  }

  .p-s-260 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mat-tab-label,
  .f-size-15-md-sm {
    font-size: 15px !important;
  }

  .f-size-17-md-sm,
  .dialog .mat-dialog-title h2 {
    font-size: 17px !important;
  }

  .f-size-18-md-sm {
    font-size: 18px !important;
  }

  .f-size-20-md-sm {
    font-size: 20px !important;
  }

  .f-size-25-md-sm {
    font-size: 25px !important;
  }

  .f-size-30-md-sm {
    font-size: 30px !important;
  }

  .m-auto-md-sm {
    margin: auto !important;
  }

  .m-0-md-sm {
    margin: 0 !important;
  }

  .p-0-md-sm {
    padding: 0 !important;
  }

  .p-15-md-sm {
    padding: 15px !important;
  }

  .mb-15-md-sm {
    margin-bottom: 15px !important;
  }

  .w-50-md-sm {
    width: 50% !important;
  }

  .w-100-md-sm {
    width: 100% !important;
  }

  .mt-15-md-sm {
    margin-top: 15px !important;
  }

  .flex-column-md-sm {
    flex-direction: column !important;
  }

  .align-items-center-md-sm {
    align-items: center !important;
  }

  .object-contain-md-sm {
    object-fit: contain !important;
  }

  .flex-column-reverse-md-sm {
    flex-direction: column-reverse !important;
  }

  .justify-content-center-md-sm {
    justify-content: center !important;
  }

  .count-list .items .card-details {
    padding: 0 !important;

    .info {
      justify-content: start !important;
    }

    &:not(:last-child) {
      border: none;
    }
  }
}

@media (max-width: 767px) {
  .mat-dialog-container {
    overflow-y: auto !important;
    max-height: 100vh !important;
    padding: 10px 20px !important;
  }

  .my-table tr th {
    font-size: 13px !important;
  }

  .d-block-sm {
    display: block !important;
  }

  .d-flex-sm {
    display: flex !important;
  }

  .w-40-px-sm {
    width: 40px !important;
  }

  .h-40-px-sm {
    height: 40px !important;
  }

  .w-100-sm {
    width: 100% !important;
  }

  .w-auto-sm {
    width: auto !important;
  }

  .d-none-sm {
    display: none !important;
  }

  .flex-column-reverse-sm {
    flex-direction: column-reverse !important;
  }

  .flex-column-sm {
    flex-direction: column !important;
  }

  .flex-row-reverse-sm {
    flex-direction: row-reverse !important;
  }

  .flex-row-sm {
    flex-direction: row !important;
  }

  .align-items-start-sm {
    align-items: flex-start !important;
  }

  .justify-content-start-sm {
    justify-content: start !important;
  }

  .order-1-sm {
    order: 1;
  }

  .order-2-sm {
    order: 2;
  }

  .order-3-sm {
    order: 3;
  }

  .order-4-sm {
    order: 4;
  }

  .order-5-sm {
    order: 5;
  }

  .order-6-sm {
    order: 6;
  }

  .order-7-sm {
    order: 7;
  }

  .order-8-sm {
    order: 8;
  }

  .h-auto-sm {
    height: auto !important;
  }

  .f-size-13-sm {
    font-size: 13px !important;
  }

  .f-size-15-sm {
    font-size: 15px !important;
  }

  .f-size-20-sm {
    font-size: 20px !important;
  }

  .p-0-sm {
    padding: 0 !important;
  }

  .m-0-sm {
    margin: 0 !important;
  }

  .m-auto-sm {
    margin: auto !important;
  }

  .p-10-sm {
    padding: 10px !important;
  }

  .mt-0-sm {
    margin-top: 0 !important;
  }

  .mt-15-sm {
    margin-top: 15px !important;
  }

  .mt-10-sm {
    margin-top: 10px !important;
  }

  .mb-10-sm {
    margin-bottom: 10px !important;
  }

  .mb-15-sm {
    margin-bottom: 15px !important;
  }

  .mb-0-sm {
    margin-bottom: 0 !important;
  }

  .p-15-sm {
    padding: 15px !important;
  }

  .px-0-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-15-sm,
  metutors-what-provide-you .mat-tab-group .mat-tab-label {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mx-15-sm {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .py-0-sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mb-5-sm {
    margin-bottom: 5px !important;
  }

  .text-start-sm {
    text-align: left !important;
  }

  .position-unset-sm {
    position: unset !important;
  }
}

@media (max-width: 576px) {
  .d-flex-xs {
    display: flex !important;
  }

  .text-center-xs {
    text-align: center !important;
  }

  .w-100-xs {
    width: 100% !important;
  }

  .w-250-xs {
    width: 250px !important;
  }

  .m-0-xs {
    margin: 0 !important;
  }

  .mb-5-xs {
    margin-bottom: 5px !important;
  }

  .mb-10-xs {
    margin-bottom: 10px !important;
  }

  .ps-2-xs {
    padding-left: .5rem !important;
  }

  .me-3-xs {
    margin-right: 1rem !important;
  }

  .flex-column-xs {
    flex-direction: column !important;
  }

  .flex-row-xs {
    flex-direction: row !important;
  }

  .justify-content-start-xs {
    justify-content: start !important;
  }

  .align-items-start-xs {
    align-items: flex-start !important;
  }

  .select-program-category .chevron-right {
    right: -10px !important;
  }

  .learning-environment .chevron-left,
  .select-program-category .chevron-left {
    left: -10px !important;
  }

  #ccc-icon {
    display: none !important;
  }

  .float-unset-xs {
    float: unset !important;
  }

  .me-table tr th {
    font-size: 12px;
    font-weight: 600;
  }

  .count-list .items {
    flex-direction: column;

    .card-details{
      padding: 0 !important;

      h3 {
        font-size: 13px;
        font-weight: 600;
      }

      span {
        font-weight: 700;
      }

      .info {
        justify-content: start !important;
      }

      &:not(:last-child) {
        border: none;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 400px) {
  .d-flex-xxs {
    display: flex !important;
  }

  .text-center-xxs {
    text-align: center !important;
  }

  .flex-column-xxs {
    flex-direction: column !important;
  }

  .w-100-xxs {
    width: 100% !important;
  }

  .d-none-xxs {
    display: none !important;
  }

  .d-inline-block-xxs {
    display: inline-block !important;
  }

  .m-0-xxs {
    margin: 0 !important;
  }

  .mt-10-xxs {
    margin-top: 10px !important;
  }
}
